import React, {FC, useEffect, useState} from 'react';
import {useGetPaymentLinkQuery} from "../../../store/API/productApi";
import {Box, Checkbox, Divider, Modal, Stack} from "@mui/material";
import IconClose from "../../../shared/assets/images/icons/iconClose";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";

interface IType {
    paymentLinkId?: any;
    stateModal?: any;
    title?: string;
    text?: string;
    price?: any;
    forexAccountData?: any;
    closeModal?: any;
    openModal: boolean;
    isRobot?: boolean;
}

const PaymentModal: FC<IType> = ({
                                     isRobot,
                                     paymentLinkId,
                                     forexAccountData,
                                     title,
                                     price,
                                     text,
                                     openModal,
                                     closeModal,
                                     stateModal
                                 }) => {

    const {data: dataPaymentLink} = useGetPaymentLinkQuery(`/product/${paymentLinkId}/pay`, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })

    const [open, setOpen] = useState(false);
    const [accept, setAccept] = useState(false)
    useEffect((() => {
        setOpen(openModal)
    }), [open, openModal, paymentLinkId, dataPaymentLink])

    const handleClose = (e: any) => {
        e.preventDefault()
        closeModal(false)
        setOpen(false)
    };
    console.log(dataPaymentLink)
    return (
        <>
            <Modal open={open} onClose={handleClose} sx={{
                "@media (max-width:980px)": {
                    maxWidth: '95%',
                    width: '100%',
                    margin: '0 auto',
                }
            }}>
                <Box sx={{maxWidth: 620}}>
                    <Stack onClick={handleClose} sx={{position: "absolute", top: 14, right: 28, cursor: "pointer"}}>
                        <IconClose/>
                    </Stack>
                    <Stack className="h2 white-90" sx={{mb: 7}}>{title}</Stack>
                    <Divider variant="fullWidth" sx={{mb: 7}}/>
                    <Stack spacing={7}>
                        <Stack className="h2 white-100" spacing={14}>
                            {
                                text ? <>
                                        <span>
                                            <span>Вы хотите продлить продукт</span>
                                            <span className="green">&nbsp;{dataPaymentLink?.title}</span>  ?
                                        </span>

                                    </>
                                    :
                                    <>
                                        <span>
                                            <span>Вы хотите подключить продукт</span>
                                            <span
                                                className="yellow">&nbsp;{dataPaymentLink?.title}&nbsp;</span>
                                            <span>на счет</span>
                                            <span className="blue">&nbsp;{forexAccountData?.login}</span>
                                            <span> ?</span>
                                        </span>

                                        {isRobot && <div>
                                            <p style={{
                                                textAlign: 'left'
                                            }}>
                                                К продукту робот можно привязать только один счет. <br/>
                                                После оплаты смена счета невозможна.
                                                Для подключения другого счета потребуется создать и оплатить новый
                                                продукт робот.
                                            </p>
                                            <div style={{
                                                marginTop: '14px',
                                            }}>
                                                <Checkbox checked={accept} onClick={() => setAccept(!accept)}/>
                                                <span style={{
                                                    fontSize: '12px',
                                                    marginLeft: '10px'
                                                }}>Согласен(а)</span>
                                            </div>

                                        </div>}
                                        <span>
                                            <span>Сумма заказа:</span>
                                            <span
                                                className="green">&nbsp;{price?.price || `${dataPaymentLink?.amount}₽`}</span>
                                            {/*<span className="green">&nbsp;{dataPaymentLink?.title}</span>*/}
                                        </span>
                                    </>
                            }

                        </Stack>
                        <Stack justifyContent="flex-end">
                            <Button
                                sx={{ml: 'auto', maxWidth: 268}}
                                color="success"
                                disabled={isRobot ? !accept : false}
                                component={Link} target="_blank" to={dataPaymentLink?.payment_url}
                            >Подтвердить и оплатить</Button>
                        </Stack>
                    </Stack>
                </Box>
            </Modal>
        </>
    );
};

export default PaymentModal;
