import React, {FC, useEffect, useState} from 'react';
import {Chip, Divider, Stack, useMediaQuery} from "@mui/material";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IconClose from "../../../shared/assets/images/icons/iconClose";
import Button from "@mui/material/Button";
import IconPlay from "../../../shared/assets/images/icons/iconPlay";
import Paper from "@mui/material/Paper";
import s from '../../../pages/products/styles.module.css'
import {
    useCreateNewProductMutation, useRobotSettingsSendMutation,
} from "../../../store/API/productApi";
import {Link} from "react-router-dom";
import SimpleModal from "./simpleModal";
import PaymentModal from "./paymentModal";
import IconNewProduct from "../../../shared/assets/images/icons/iconNewProduct";
import AccountConnectionList from "../accountConnectionList";
import IconConnected from "../../../shared/assets/images/icons/iconConnected";
import CustomRange from "../../../shared/components/customRange";

interface IType {
    stateModal?: any;
    openModal: boolean;
    closeModal: any;
    isOPenBtn?: boolean
    isError?: boolean
}

const tariffData = [
    {
        id: 1,
        title: 'До 2.000$',
        price: '1.000₽',
        dateValid: '30 дней',
        slug: 'copier2k'
    },
    {
        id: 2,
        title: 'До 5.000$',
        price: '2.000₽',
        dateValid: '30 дней',
        slug: 'copier5k'
    },
    {
        id: 3,
        title: 'До 10.000$',
        price: '3.000₽',
        dateValid: '30 дней',
        slug: 'copier10k'
    },
    {
        id: 4,
        title: 'До 20.000$',
        price: '6.000₽',
        dateValid: '30 дней',
        slug: 'copier20k'
    },
]
const AddProductModal: FC<IType> = ({stateModal, openModal, closeModal}) => {
    const [paymentLinkId, setPaymentLinkId] = useState(0)
    const [slug, setSlug] = useState('');

    const [createNewProduct, {
        data: dataPayLink,
        error: productError,
        isLoading: productLoading
    }] = useCreateNewProductMutation()
    const [robotSettingsSend] = useRobotSettingsSendMutation()
    const mediaQuery = useMediaQuery('(min-width:980px)');
    const [open, setOpen] = useState(false)
    const [openPaymentModal, setOpenPaymentModal] = useState(false)
    const [openVideoModal, setOpenVideoModal] = useState(false)
    const [visibleText, setVisibleText] = useState(true)
    const [fullNameTarif, setFullNameTarid] = useState('')
    const [tariff, setTariff] = useState(0)
    const [forexAccountData, setForexAccountData] = useState({id: null, login: ''});
    const [step, setStep] = useState<any>(1);
    const [robotSettings, setRobotSettings] = useState(false)
    const [settingsValue, setSettingsValue] = useState({
        risk: 15,
        fix_lose: 0,
        stop_at_dropdown: 40,
        drying: false,
        take_dropdown_all_pairs: false,
        remember_balance: true,
        starting_after_stopping: true,
    })

    useEffect((() => {
        setPaymentLinkId(dataPayLink ? dataPayLink?.data?.id : 0)
        setOpen(openModal)
    }), [open, openModal, dataPayLink])

    const handlerClose = () => {
        closeModal(false)
        setOpen(false)
        setStep(1)
    };
    const createProduct = () => {
        createNewProduct({
            body: {
                forex_account_id: forexAccountData.id
            },
            slug: (stateModal.id === 1 && slug) || (stateModal.id === 3 && 'robot') || (stateModal.id === 4 && 'robot2')
        }).then((res: any) => {

            if (stateModal.id === 3 || stateModal.id === 4) {
                robotSettingsSend({
                    id: res?.data?.data?.id,
                    body: {...settingsValue}
                }).then((res) => {
                    setOpenPaymentModal(true)
                })
                return
            }
            setOpenPaymentModal(true)
        })
    };

    return (
        <>
            <Modal open={open} onClose={handlerClose} sx={{
                "@media (max-width:980px)": {
                    maxWidth: '95%',
                    width: '100%',
                    margin: '0 auto',
                }
            }}>
                <Box sx={{
                    maxWidth: 780,
                    background:
                        (stateModal.id === 3) ?
                            `linear-gradient(180deg, #33223A 0%, rgba(51, 34, 58, 0) 93.75%)`
                            : `linear-gradient(180deg, #242D28 0%, rgba(36, 45, 40, 0) 93.75%)`,
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '14px',
                    }}>
                        <Stack className="h2 white-90">Продукт</Stack>
                        <Stack onClick={handlerClose}
                               sx={{cursor: "pointer"}}
                        >
                            <IconClose/>
                        </Stack>
                    </div>
                    <Divider variant="fullWidth" sx={{mb: 7}}/>
                    {
                        (step === 1) ?
                            <>
                                <Stack direction={mediaQuery ? "row" : "column-reverse"} spacing={7}
                                       justifyContent="space-between"
                                       sx={{mb: 14}}>
                                    <Stack>
                                        <Stack
                                            spacing={7}
                                            sx={
                                                visibleText ?
                                                    {
                                                        maxHeight: 300,
                                                        maxWidth: 460,
                                                        margin: '0 auto',
                                                        background: `linear-gradient(180deg, #D9D9D9 41.15%, rgba(217, 217, 217, 0) 100%)`,
                                                        backgroundClip: 'text',
                                                        textFillColor: 'transparent',
                                                        overflow: 'hidden',
                                                        "@media (max-width:980px)": {
                                                            maxWidth: '100%'
                                                        }
                                                    } :
                                                    {
                                                        maxWidth: 460,
                                                        color: '#BDBDBD',
                                                        "@media (max-width:980px)": {
                                                            maxWidth: '100%'
                                                        }
                                                    }


                                            }
                                        >
                                            {(stateModal.id === 3) && <>
                                                <p>Робот работает по двум стратегиям:
                                                    Первая стратегия робота в торговле основана на алгоритме, который
                                                    ищет возможность отката в основном тренде. Когда цена начинает
                                                    изменяться в направлении тренда, робот открывает ордер, ожидая
                                                    разворот. Затем, при наступлении разворота, ордер закрывается с
                                                    прибылью.</p>

                                                <p>Вторая стратегия робота предполагает открытие усредняющих ордеров -
                                                    при предполагаемом развороте цены в нужную сторону. Робот открывает
                                                    ордер, когда цена достигает уровня, на котором она должна
                                                    развернуться. Если цена все же продолжает идти в неожиданном
                                                    направлении, робот открывает еще один ордер, усредняя его цену
                                                    открытия. Таким образом, робот увеличивает вероятность закрытия с
                                                    прибылью в случае разворота цены.</p>

                                                <p>Обе стратегии позволяют роботу осуществлять торговлю автоматически и
                                                    максимизировать возможность получения прибыли. Каждая стратегия
                                                    имеет свои преимущества и недостатки, поэтому робот может
                                                    использовать обе стратегии одновременно или переключаться между ними
                                                    в зависимости от рыночной ситуации.</p>

                                                <p>Рабочие инструменты:
                                                    AUDCAD, AUDUSD, CADCHF, EURAUD, EURCAD, EURGBP, GBPAUD, GBPCAD,
                                                    GBPNZD, GBPUSD, USDCAD, USDCHF</p>

                                                <p>Таймфрейм: M15</p>

                                            </> || stateModal.id === 4 && <>
                                                <p>Робот торгует по новой авторской стратегии с большим количеством
                                                    ордеров с относительно маленькими объемами.</p>

                                                <p>Рабочие инструменты: AUDCAD, AUDCHF, AUDNZD, AUDUSD, EURAUD, EURCAD,
                                                    EURGBP, GBPAUD, GBPCAD, GBPNZD, GBPUSD, NZDCAD, NZDUSD, USDCAD</p>

                                                <p>Таймфрейм: M15</p>
                                            </> || <>
                                                <p>
                                                    CopyTen - инновационный инструмент, который позволяет собрать свой
                                                    портфель
                                                    из нескольких трейдеров и выделить на каждого часть своего депозита.
                                                    Это помогает снизить риск при неудачной торговле одного трейдера и
                                                    сохранить
                                                    средства.
                                                </p>
                                                <p>
                                                    Гибкие настройки инструмента позволяют детально настроить
                                                    копирование от
                                                    каждого трейдера, что помогает увеличить прибыль и снизить риски.
                                                </p>
                                                <p>
                                                    CopyTen предлагает несколько тарифов, которые зависят от размера
                                                    вашего
                                                    депозита. Вы можете выбрать тариф, который наиболее подходит для
                                                    ваших
                                                    инвестиционных потребностей:
                                                </p>
                                                <p>
                                                    - CopyTen 2000$ - 1000р. в месяц;
                                                    - CopyTen 5000$ - 1800р. в месяц;
                                                    - CopyTen 10000$ - 3000р. в месяц;
                                                    - CopyTen 20000$ - 6000р. в месяц.
                                                </p>
                                                <p>
                                                    Станьте риск менеджером и используйте CopyTen для эффективного
                                                    управления
                                                    своими инвестициями.
                                                </p>
                                            </>}
                                        </Stack>
                                        {
                                            visibleText &&
                                            <Button onClick={() => setVisibleText(false)} sx={{
                                                color: "#BDBDBD",
                                                "@media (max-width:560px)": {
                                                    marginTop: '-30px'
                                                }

                                            }}>
                                                Читать подробнее
                                            </Button>
                                        }

                                    </Stack>
                                    <Stack spacing={7}>
                                        <img src={stateModal.image} alt="Копировальщик" className={s.img_product}/>

                                        <span style={{
                                            margin: '20px auto',
                                        }}
                                              className={(stateModal.id === 3) ? "h1 pink" : "h1 green"}>{stateModal.name}</span>

                                        <Button onClick={() => setOpenVideoModal(true)} color="error"
                                                startIcon={<IconPlay/>} sx={{
                                            "@media (max-width:560px)": {
                                                maxWidth: '75%',
                                                width: '100%',
                                                margin: '0 auto !important'
                                            }
                                        }}>
                                            Посмотреть презентацию
                                        </Button>
                                    </Stack>

                                </Stack>

                                <Button
                                    fullWidth
                                    onClick={() => {
                                        setStep(
                                            (stateModal.id === 1 && 2) ||
                                            ((stateModal.id === 3 || stateModal.id === 4) && 3) || 2
                                        )
                                        setRobotSettings(stateModal.id === 3 || stateModal.id === 4)
                                    }}
                                    color="neutral"
                                    startIcon={
                                        ((stateModal.id === 3 || stateModal.id === 4) &&
                                            <IconConnected fill={'#828282'} connected={false}/>) ||
                                        (stateModal.id === 1 && <IconNewProduct/>)
                                    }
                                    sx={{height: 48, justifyContent: 'flex-start', color: '#BDBDBD', fontSize: 16}}
                                >
                                    {stateModal.id === 1 && 'Перейти к тарифам'}
                                    {(stateModal.id === 3 || stateModal.id === 4) && 'Подключить'}
                                </Button>
                            </>
                            : (step === 2) ?
                                <Stack spacing={7}>
                                    <Stack className="h2">Выберите тариф для продукта</Stack>
                                    {
                                        tariffData.map(item =>
                                            <Paper
                                                key={item.id}
                                                onClick={() => {
                                                    setSlug(item.slug)
                                                    setTariff(item.id)
                                                    setFullNameTarid(item.title)
                                                }}
                                                sx={{
                                                    borderColor: tariff === item.id ? '#6FCF97' : '',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <Stack direction={mediaQuery ? "row" : "column"}
                                                       justifyContent="space-between" alignItems="center"
                                                       spacing={14}>
                                                    <Stack className="h2" direction="row">
                                                        <span className="green">CopyTen&nbsp;</span>
                                                        <span className="white-100">{item.title}</span>
                                                    </Stack>
                                                    <Stack direction="row" justifyContent="space-between"
                                                           alignItems="center" spacing={14} sx={{
                                                        "@media (max-width:980px)": {
                                                            width: '100%',
                                                        }
                                                    }}>

                                                        <Stack alignItems="center" sx={{
                                                            ml: 'auto',
                                                            "@media (max-width:980px)": {
                                                                width: '50%',
                                                            }
                                                        }}>
                                                            <span className="white-90 subHeaders">Цена</span>
                                                            <span className="subHeadersBold yellow">{item.price}</span>
                                                        </Stack>

                                                        <Divider orientation="vertical" flexItem/>

                                                        <Stack alignItems="center" sx={{
                                                            "@media (max-width:980px)": {
                                                                width: '50%',
                                                            }
                                                        }}>
                                                            <span className="white-90 subHeaders">Срок действия</span>
                                                            <span
                                                                className="subHeadersBold white-100">{item.dateValid}</span>
                                                        </Stack>
                                                    </Stack>
                                                    <Chip
                                                        sx={{
                                                            "@media (max-width:980px)": {
                                                                width: '100%'
                                                            }
                                                        }}
                                                        variant="outlined"
                                                        label={tariff === item.id ? "Выбрано" : "Выбрать"}
                                                        color={tariff === item.id ? "success" : "neutral"}
                                                    />

                                                </Stack>
                                            </Paper>
                                        )
                                    }
                                </Stack>
                                : (step === 3) ?

                                    <AccountConnectionList setForexAccountData={setForexAccountData}
                                                           stateModal={stateModal}
                                                           fullNameTarif={fullNameTarif}/>

                                    : (step === 4) ?
                                        productError ? 'ошибка при добавлении'
                                            :
                                            <Stack className="h2 white-100" spacing={14}>
                                                <span>
                                                    <span>Вы хотите подключить продукт</span>
                                                    <span
                                                        className="yellow">&nbsp;{stateModal.name} {stateModal.priceTitle}&nbsp;</span>
                                                    <span>на счет</span>
                                                    <span className="blue">&nbsp;{forexAccountData.login}</span>
                                                </span>
                                                <span>
                                            <span>Сумма заказа:</span>
                                            <span className="green">&nbsp;{stateModal.price}</span>
                                        </span>
                                            </Stack>
                                        : null
                    }
                    <Stack direction="row" justifyContent="flex-end" spacing={7} sx={{mt: 7}}>

                        {
                            (step === 1) ?
                                <Button onClick={handlerClose} color="neutral">Отмена</Button>
                                :
                                <Button onClick={() => {
                                    setForexAccountData({id: null, login: ''})
                                    setStep(((stateModal.id === 3 || stateModal.id === 4) && step === 3) ? 1 : step - 1)
                                }} color="neutral">Назад</Button>
                        }

                        {
                            (step === 2) ?
                                <Button disabled={slug === ''} onClick={() => setStep(step + 1)}
                                        color="success">Продолжить</Button>
                                : (step === 3) ?
                                    <Button onClick={createProduct} disabled={forexAccountData.id === null} color="success">Создать
                                        заказ</Button>
                                    :
                                    (step === 4) ?
                                        <Button
                                            onClick={handlerClose}
                                            color="success"
                                            component={Link} target="_blank" to="/trader-dashboard"
                                        >Подтвердить и оплатить</Button>
                                        :
                                        null
                        }
                    </Stack>

                </Box>
            </Modal>
            {
                <SimpleModal openModal={openVideoModal} closeModal={setOpenVideoModal} title="Смотреть видео">
                    {(stateModal.id === 3 || stateModal.id === 4) ?
                        <iframe height="315" style={{
                            maxWidth: '560px',
                            width: '100%'
                        }} src="https://www.youtube.com/embed/r3Rw4KmOkAI"
                                title="Робот новые настройки - веб 24.07.23" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe> :
                        <iframe height="315" style={{
                            maxWidth: '560px',
                            width: '100%'
                        }} src="https://www.youtube.com/embed/cwwmEg2JOEw" title="CopyTen"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>
                    }

                </SimpleModal>
            }
            {robotSettings &&
                <SimpleModal maxWidth={460} openModal={robotSettings} closeModal={() => setRobotSettings(false)}
                             title="Робот">
                    <Stack className="h2 white-100" spacing={14}>

                        <p>Настройки</p>

                        <span style={{
                            marginTop: '5px'
                        }}>
                            <span style={{
                                fontSize: '12px'
                            }} className="green">Вы можете пропустить этот пункт и настроить данный продукт после подключения в </span> <span
                            style={{
                                fontSize: '12px'
                            }}>&nbsp;{'Дашборде счета'}</span>
                        </span>
                        <CustomRange onChange={(value) => setSettingsValue({...settingsValue, risk: value})}
                                     minValue={0}
                                     title="Риск"
                                     defaultValue={15}
                                     maxValue={50}
                                     required
                                     isNeedSwitch={false}
                                     isSwitch
                                     isSliderRange/>
                        <CustomRange onChange={(value) => setSettingsValue({...settingsValue, fix_lose: value})}
                                     minValue={0}
                                     title="Фиксация убытка"
                                     defaultValue={0}
                                     maxValue={100}
                                     required
                                     isNeedSwitch={false}
                                     isSwitch
                                     isSliderRange/>
                        <CustomRange
                            isNeedOpacity
                            onChange={(value) => setSettingsValue({
                                ...settingsValue,
                                take_dropdown_all_pairs: value === null ? false : true
                            })}
                            title='Учитывать просадку всех инструментов'
                            required
                            isSwitchChecked={settingsValue.take_dropdown_all_pairs}
                            isNeedSwitch={true}
                            isSwitch
                            isSliderRange={false}
                        />
                        <CustomRange
                            isNeedOpacity
                            onChange={(value) => setSettingsValue({...settingsValue, stop_at_dropdown: value})}
                            minValue={0}
                            title='Остановка при просадке'
                            defaultValue={settingsValue.stop_at_dropdown}
                            maxValue={100}
                            required
                            isNeedSwitch={false}
                            isSwitch
                            isSliderRange
                        />

                        <CustomRange
                            isNeedOpacity
                            onChange={(value) => setSettingsValue({
                                ...settingsValue,
                                starting_after_stopping: value === null ? false : true
                            })}
                            title='Авто-продолжение после остановки'
                            required
                            isSwitchChecked={settingsValue.starting_after_stopping}
                            isNeedSwitch={true}
                            isSwitch
                            isSliderRange={false}
                        />

                        <CustomRange
                            isNeedOpacity
                            onChange={(value) => {

                                setSettingsValue({
                                    ...settingsValue,
                                    remember_balance: value === null ? false : true
                                })
                            }}
                            title='Запоминать баланс'
                            required
                            isSwitchChecked={settingsValue.remember_balance}
                            isNeedSwitch={true}
                            isSwitch
                            isSliderRange={false}
                        />

                        <CustomRange
                            isNeedOpacity
                            onChange={(value) => {

                                setSettingsValue({
                                    ...settingsValue,
                                    drying: value === null ? false : true
                                })
                            }}
                            defaultValue={settingsValue.drying === false ? null : 0}
                            title='Сушка'
                            required
                            isSwitchChecked={settingsValue.drying}
                            isNeedSwitch={true}
                            isSwitch
                            isSliderRange={false}
                        />

                        <Stack direction="row" justifyContent="flex-end" spacing={7} sx={{mt: 7}}>


                            <Button onClick={() => {
                                setRobotSettings(false)
                                setStep(1)
                            }} color="neutral">Назад</Button>

                            <Button color="success" onClick={() => {
                                setRobotSettings(false)
                            }}>Продолжить</Button>

                        </Stack>

                    </Stack>
                </SimpleModal>
            }

            {
                (openPaymentModal && dataPayLink) &&
                <PaymentModal isRobot={Boolean(stateModal.id === 3 || stateModal.id === 4)}
                              forexAccountData={forexAccountData}
                              title="Новый заказ"
                              price={tariffData.find((f) => f.id === tariff)}
                              stateModal={stateModal} paymentLinkId={paymentLinkId}
                              openModal={openPaymentModal}
                              closeModal={setOpenPaymentModal}/>
            }

        </>
    );
}

export default AddProductModal;
