import React, {FC, useEffect, useState} from 'react';
import {Chip, Paper, Slider, Stack, Switch} from "@mui/material";

interface IType {
    title?: string;
    required?: boolean;
    minValue?: number;
    maxValue?: number;
    defaultValue?: any;
    step?: number;
    isSwitch?: boolean;
    isSwitchChecked?: boolean;
    isNeedSwitch?: boolean;
    isSliderRange?: boolean;
    onChange?: (value: any) => void;
    isNeedOpacity?: boolean;
    onChangeSwift?: (value: any) => void;
}

const CustomRange: FC<IType> = ({
                                    isNeedOpacity = false,
                                    title,
                                    required,
                                    minValue,
                                    maxValue,
                                    isSwitch,
                                    isSwitchChecked = true,
                                    step,
                                    defaultValue,
                                    isSliderRange,
                                    onChange,
                                    isNeedSwitch = true,
                                    onChangeSwift
                                }) => {
    const [marks, setMarks] = useState([
        // {value: minValue !== undefined ? minValue : 0, label: minValue ? minValue : 0},
        {value: (0), label: (0)},
        {value: maxValue !== undefined ? maxValue : 500, label: maxValue ? maxValue : 500},
    ]);

    const [value, setValue] = useState((defaultValue === null) ? null : (defaultValue || (minValue || 0)));
    const [invisible, setInvisible] = useState(!isSwitchChecked);
    const [switchChecked, setSwitchChecked] = useState(isSwitchChecked);

    useEffect(() => {
        if (isSwitchChecked) setSwitchChecked(isSwitchChecked)

    }, [isSwitchChecked])

    const handleBadgeVisibility = () => {
        setSwitchChecked(!switchChecked)
        setInvisible(!invisible);
        if (onChangeSwift) onChangeSwift(invisible)
    };

    const handleChange = (event: any, newValue: number | number[]) => {
        if (typeof newValue === 'number') {
            setValue(newValue);
            if (onChange) {
                onChange(newValue)
            }
        }
    }

    useEffect(() => {
        if (value && maxValue) {
            if (value > maxValue) {
                handleChange('', maxValue)
            }
            // if (value < (minValue || 0)) {
            //     handleChange('', (minValue || 0))
            // }

        }
    }, [value])

    useEffect(() => {
        if (onChange) {
            onChange(value)
        }
    }, [])
    const percentRedLine = minValue ? ((minValue * 100) / 125) : 0

    return (

        <Paper
            sx={{
                background: isNeedOpacity ? 'rgba(255,255,255,0) !important' : '',
                "@media (min-width:980px)": {
                    padding: `12px 8px`,
                }, borderRadius: `5px`,
            }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <span className="subHeaders">{title} {required && <span className="red">*</span>}</span>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    {isSliderRange &&
                        // <Chip label={value || 0} variant="filled" color="neutral" sx={{height: 23, p: `8px 2px`}}/>}
                        <input disabled={!switchChecked} type="number" className={'input_number'}
                               value={Number(value).toString() || (minValue || 0)}
                               max={maxValue}
                               min={(minValue || 0)}
                               onChange={(e) => {
                                   handleChange(e, +e.target.value || (minValue || 0))
                               }}/>}
                    {isNeedSwitch && <>
                        {
                            isSwitchChecked !== undefined ?
                                <Switch
                                    checked={switchChecked}
                                    size="small"
                                    onChange={(e) => {

                                        setSwitchChecked(e.target.checked)
                                        handleBadgeVisibility()
                                        if (!e.target.checked) {

                                            onChange && onChange(null)
                                        } else {

                                            onChange && onChange(value || (minValue || 0))
                                        }
                                    }}
                                />
                                : isSwitch ?

                                    <Switch
                                        defaultChecked
                                        checked={switchChecked}
                                        size="small"
                                        onChange={(e) => {
                                            handleBadgeVisibility()

                                            if (!e.target.checked) {

                                                onChange && onChange(null)
                                            } else {

                                                onChange && onChange(value || (minValue || 0))
                                            }
                                        }}
                                    />

                                    : null
                        }
                    </>}
                </Stack>
            </Stack>
            {
                isSliderRange &&
                <Stack sx={{m: 7, mb: 0, position: 'relative', marginTop: '30px',}}>
                    <Slider
                        size="small"
                        defaultValue={defaultValue ? defaultValue : (minValue || 0)}
                        //@ts-ignore
                        value={value}
                        min={(0)}
                        max={maxValue ? maxValue : 500}
                        step={step ? step : 1}
                        sx={{
                            padding: '0 !important',
                        }}
                        onChange={handleChange}
                        valueLabelDisplay="off"
                        marks={marks}
                        disabled={invisible}
                    />
                    {(title === 'Риск' && (minValue !== 0 || minValue !== undefined || minValue !== null)) &&
                        <div style={{
                            position: "absolute",
                            height: '2px',
                            width: `calc(${percentRedLine >= 100 ? 100 : percentRedLine - 1.2}%)`,
                            background: '#d53737',
                            zIndex: '9',
                            top: '0px',
                            left: '0px'
                        }
                        }/>}
                </Stack>
            }
        </Paper>
    );
};

export default CustomRange;
