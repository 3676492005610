import React, {useLayoutEffect, useMemo} from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {random_id} from "../../../utils/random_id";
import s from './styles.module.css'

const color1 = am5.color(0x56CCF2)
const color2 = am5.color(0x6FCF97)
const color3 = am5.color(0xFF8888)

interface T {
    x: null | string,
    type: number,
    value: number,
    d: string
}


const BalanceChart = ({balanceChartData}: any) => {
    const randomID = random_id()

    const data2 = useMemo(() => {
        return balanceChartData?.map((el: T, i: number) => {
            const dateandtime: any = el.d ? el.d.split(' ') : null
            const date = dateandtime === null ? null : dateandtime[0].split('-')
            let dateConverter = date === null ? null : new Date(`${date[2]}-${date[1]}-${date[0]}`)

            return ({
                ...el, index: i,
                // d: dateConverter === null ? null : `${dateConverter.getDate() >= 10 ? dateConverter.getDate() : `0${dateConverter.getDate()}`}-${dateConverter.getMonth() + 1 >= 10 ? dateConverter.getMonth() + 1 : `0${dateConverter.getMonth() + 1}`}-${dateConverter.getFullYear()}`,
                d: dateandtime === null ? null : dateandtime[0],
                strokeSettings: {
                    stroke:

                        (balanceChartData[i + 1]?.type === 0 && color1) ||
                        (balanceChartData[i + 1]?.type === 1 && color2) ||
                        (balanceChartData[i + 1]?.type === -1 && color3) ||
                        (el?.type === 0 && color1) ||
                        (el?.type === 1 && color2) ||
                        (el?.type === -1 && color3),

                    // (el?.type === 0 && color1) ||
                    // (el?.type === 1 && color2) ||
                    // (el?.type === -1 && color3),
                }

            })
        })

    }, [balanceChartData])


    useLayoutEffect(() => {

        let root = am5.Root.new(randomID);

        am5.Graphics.new(root, {
            stroke: am5.color(0x000000),
            fill: am5.color(0x990000)
        });

        root._logo?.dispose()
        root.setThemes([
            am5themes_Animated.new(root)
        ]);


        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "none",
            wheelY: "none",
            cursor: am5xy.XYCursor.new(root, {}),
            pinchZoomX: false,
        }));

        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "none",
        }));

        cursor.lineY.set("visible", false);
        cursor.lineX.set("stroke", am5.color(0xffffff));
        cursor.lineX.set("strokeWidth", 2);

        let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "index",
            forceHidden: true,
            visible: false,

            renderer: am5xy.AxisRendererX.new(root, {
                forceHidden: true,
            }),
            tooltip: am5.Tooltip.new(root, {
                visible: false,
                forceHidden: true,
            }),
            startLocation: 0.5,
            endLocation: 0.5,
        }));

        xAxis.data.setAll(data2);

        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            extraTooltipPrecision: 2,
            numberFormat: "#'$'",

            renderer: am5xy.AxisRendererY.new(root, {
                minGridDistance: 20,
                opposite: true,

            })
        }));

        yAxis.get("renderer").labels.template.setAll({
            fill: am5.color(0xFF0000),
            fontSize: "10px",
            rotation: 20,

        });
        const series = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            categoryXField: "index",
        }));

        series.data.processor = am5.DataProcessor.new(root, {
            colorFields: ["strokeSettings.stroke"],
        });

        let tooltip = am5.Tooltip.new(root, {
            getFillFromSprite: false,
            labelText: "[bold][#56CCF2]{valueY}$[/]\n{d}",
            pointerOrientation: 'horizontal'
        });

        tooltip?.get("background")?.setAll({
            strokeOpacity: 1,
            stroke: am5.color(0x3C3C3C),
            fillOpacity: .5,
            fill: am5.color(0x1F1F1F),
        });
        series.set("tooltip", tooltip);
        series.set("fill", am5.color(0x3C3C3C)); // set Series color to red

        series.strokes.template.setAll({
            templateField: "strokeSettings",
            strokeWidth: 1
        });

        xAxis.get("renderer").labels.template.setAll({
            fill: am5.color(0xffffff),
            forceHidden: true,
        });

        yAxis.get("renderer").labels.template.setAll({
            fill: am5.color(0x56CCF2),
        });

        xAxis.get("renderer").grid.template.setAll({
            stroke: am5.color(0xffffff),
        })

        yAxis.get("renderer").grid.template.setAll({
            stroke: am5.color(0xffffff),
        })

        root.interfaceColors.set("grid", am5.color(0xCCCCCC));

        series.data.setAll(data2);

        series.appear(1000);
        return () => {
            root.dispose();
        };
    }, [data2]);

    return (
        <div>
            <div id={randomID} style={{width: "100%", height: "227px"}}></div>
            <div className={s.info_box}>
                <div className={s.info_box_item}>
                    <div style={{
                        backgroundColor: '#6FCF97'
                    }}/>
                    <p>Пополнение</p>
                </div>
                <div className={s.info_box_item}>
                    <div style={{
                        backgroundColor: '#FF8888'
                    }}/>
                    <p>Снятие</p>
                </div>
                <div className={s.info_box_item}>
                    <div style={{
                        backgroundColor: '#56CCF2'
                    }}/>
                    <p>Сделки</p>
                </div>
            </div>
        </div>
    );
};

export default BalanceChart;
