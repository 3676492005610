import React, {FC, useState} from 'react';
import {Button, Chip, Stack, useMediaQuery} from "@mui/material";
import IconEdit from "../../shared/assets/images/icons/iconEdit";
import SimpleModal from "./modal/simpleModal";
import CustomInput from "../../shared/UI/customInput";
import {useInput} from "../../hooks/useInput";
import {useUpdateAccountMutation} from "../../store/API/userApi";
import {Server} from "../../types";

interface T {
    accountType?: Server;
    accountLogin?: number | string;
    status?: number | string;
    accountNumber?: number | string;
    accountName?: string;
    productType?: string;
    type?: number;
    product_data?: any;
}


const AccountType: FC<T> = ({
                                accountType,
                                accountLogin,
                                status,
                                accountNumber,
                                productType,
                                accountName,
                                product_data,
                                type
                            }) => {
    const [updateAccount] = useUpdateAccountMutation()
    const mediaQuery = useMediaQuery('(min-width:980px)');
    const [openModal, setOpenModal] = useState(false);
    const nameAccount = useInput(accountName, false, false)

    const handlerEdit = (e: any) => {
        setOpenModal(false)
        updateAccount({
            body: {
                name: nameAccount.value,
            },
            id: accountNumber
        }).then((e) => {
            console.log(e)
        })
    }
    console.log(product_data)
    return (
        <Stack
            direction={"row"}
            alignItems="center"
            spacing="auto"
            sx={{padding: `14px 28px`}}
        >
            <Stack spacing={2} sx={{mb: mediaQuery ? 0 : 4}}>
                <span className="subHeaders white-80">{accountLogin}</span>
                <Stack direction="row" spacing={4}>
                    <Stack className="h2 white-100" sx={{
                        maxWidth: 100,
                        display: 'inline-block',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                    }}>{accountName ? accountName : 'Имя счета'}</Stack>
                    <Stack
                        onClick={() => {
                            setOpenModal(true)
                        }}
                        sx={{zIndex: 100, cursor: 'pointer'}}>
                        <IconEdit/>
                    </Stack>
                </Stack>
            </Stack>
            {
                status === 1 &&
                <Stack direction="row" spacing={4}>

                    {
                        productType &&
                        <Chip
                            label={(product_data?.slug === 'robot' && 'Робот Aggressive') || (product_data?.slug === 'robot2' && 'Робот Conservative') || productType}
                            variant="outlined" color="success" sx={
                            {
                                pr: 0, pl: 0,
                                color: (type === 1 && product_data?.slug === 'robot') ? '#BB6BD9' : ''
                            }
                        }/>
                    }
                    {
                        accountType?.type === 0 ?
                            <Chip label="Центовый" variant="filled" color="neutral" sx={{pr: 0, pl: 0}}/>
                            : accountType?.type === 1 ?
                                <Chip label="Долларовый" variant="filled" color="warning" sx={{pr: 0, pl: 0}}/>
                                : null
                    }

                </Stack>
            }

            <SimpleModal maxWidth={460} title="Имя счета" openModal={openModal} closeModal={setOpenModal}>
                <Stack spacing={7}>
                    <CustomInput dataInput={nameAccount} label="Имя счета" inputType="text"/>
                </Stack>
                <Stack direction="row" justifyContent="flex-end" spacing={7} sx={{mt: 7}}>
                    <Button onClick={() => setOpenModal(false)} color="error">Отмена</Button>
                    <Button onClick={handlerEdit} color="success">Сохранить</Button>
                </Stack>
            </SimpleModal>
        </Stack>
    );
};

export default AccountType;
