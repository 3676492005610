import React, {FC, useEffect, useState} from 'react';
import TabsItem from "../../entities/components/tabsItem";
import Box from "@mui/material/Box";
import {useMediaQuery} from "@mui/material";
import TabsHeader from "../../entities/components/tabsHeader";
import {useAppDispatch, useAppSelector} from "../../hooks/useRedux";
import {barChartSlice} from "../../store/slice/barChartSlice";
import IconTabs from "../../shared/assets/images/icons/iconTabs";
import {ToastContainer} from "react-toastify";
import Tabs1 from "./tabs_1";
import Tabs2 from "./tabs_2";


interface IType {
    product?: any;
    login?: any;

    traderDashboard?: boolean;
    dataTrader?: any;
    dataDashboard?: any;
    balanceChartData?: any;
    dataTradersChart?: any;
    dataSymbol?: any;
    dataChartDay?: any;
    dataChartHours?: any;
    dataChartMonths?: any;
    dataChartDrawdown?: any;
    dataBalanceGain?: any;
    dataChartDrawdownAndGain?: any;
    isLoadingSymbol?: any;
    changePage?: any;
    page?: any;

    balanceChartUrl?: any;
    balanceGainChartUrl?: any;
    dayChartUrl?: any;
    hoursChartUrl?: any;
    tradersChartUrl?: any;
    drawdownChartUrl?: any;
    drawdownAndGainChartUrl?: any;
    isTrader?: any;
    updateDeposit?: any;
    isLoadingAccount?: any;
    isTraderDasboard?: boolean;

}


const DashboardTabs: FC<IType> = ({
                                      isTraderDasboard = true,
                                      isLoadingAccount,
                                      updateDeposit,
                                      product,
                                      login,
                                      traderDashboard,
                                      dataTrader,
                                      dataDashboard,
                                      balanceChartData,
                                      dataTradersChart,
                                      dataSymbol,
                                      dataChartDay,
                                      dataChartHours,
                                      dataChartMonths,
                                      dataChartDrawdown,
                                      dataBalanceGain,
                                      dataChartDrawdownAndGain,
                                      isLoadingSymbol,
                                      balanceChartUrl,
                                      balanceGainChartUrl,
                                      dayChartUrl,
                                      hoursChartUrl,
                                      tradersChartUrl,
                                      drawdownChartUrl,
                                      drawdownAndGainChartUrl,
                                      isTrader,
                                  }) => {

    const {barChartData} = useAppSelector(state => state.barChartReducer)
    const dispatch = useAppDispatch()
    const {fetchBarChart} = barChartSlice.actions

    const mediaQuery = useMediaQuery('(min-width:980px)');
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [value, setValue] = useState(0);

    useEffect(() => {
        if (sidebarVisible && !mediaQuery) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [sidebarVisible, mediaQuery])

    useEffect((() => {

        if (dataSymbol) dispatch(fetchBarChart(dataSymbol))
        if (!mediaQuery) {
            setSidebarVisible(false)
        } else {
            setSidebarVisible(true)
        }
    }), [mediaQuery, dataSymbol])

    return (
        <>
            <Box sx={{width: '100%'}}>
                <ToastContainer
                    icon={false}
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    limit={3}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{
                        minWidth: '300px',
                        width: 'fit-content'
                    }}
                    theme="light"
                />
                <TabsHeader
                    tabsName={[
                        {name: 'Дашборд', icon: <IconTabs/>},
                        {name: 'История', icon: <IconTabs/>},
                    ]}
                    tabsValue={value} onTabsChange={setValue}/>

                <TabsItem value={value} index={0}>
                    <Tabs1 isTraderDasboard={isTraderDasboard} traderDashboard={traderDashboard} dataTrader={dataTrader}
                           isLoadingAccount={isLoadingAccount}
                           dataDashboard={dataDashboard} balanceChartData={balanceChartData}
                           balanceChartUrl={balanceChartUrl} dataBalanceGain={dataBalanceGain}
                           balanceGainChartUrl={balanceGainChartUrl}
                           dataChartDrawdownAndGain={dataChartDrawdownAndGain}
                           drawdownAndGainChartUrl={drawdownAndGainChartUrl}
                           dataChartDrawdown={dataChartDrawdown}
                           drawdownChartUrl={drawdownChartUrl}
                           dataTradersChart={dataTradersChart}
                           tradersChartUrl={tradersChartUrl}
                           dataSymbol={dataSymbol}
                           barChartData={barChartData}
                           dataChartDay={dataChartDay}
                           dataChartHours={dataChartHours}
                           dataChartMonths={dataChartMonths}
                           sidebarVisible={sidebarVisible}
                           mediaQuery={mediaQuery}
                           updateDeposit={updateDeposit}
                           setSidebarVisible={setSidebarVisible}/>
                </TabsItem>
                <TabsItem value={value} index={1}>
                    {value === 1 && <Tabs2 isTrader={isTrader}
                                           dataTrader={dataTrader}/>}
                </TabsItem>

            </Box>
        </>
    );
};

export default DashboardTabs;
