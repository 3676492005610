const IconTabs = () => {
    return (
        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16 17.5001H1C0.73459 17.5027 0.479352 17.398 0.292329 17.2097C0.105306 17.0214 0.00239561 16.7654 0.007 16.5H0V2.5C0 1.39543 0.89543 0.5 2 0.5H8C8.22336 0.500213 8.44022 0.575198 8.616 0.713L10.9 2.5H16C17.1046 2.5 18 3.39543 18 4.5V8.5H19C19.3361 8.50004 19.6498 8.66895 19.8348 8.94959C20.0198 9.23023 20.0514 9.58505 19.919 9.894L16.919 16.894C16.7614 17.2616 16.4 17.5001 16 17.5001ZM4.66 10.5L2.517 15.5H15.341L17.484 10.5H4.66ZM2 4.5V11.63L3.081 9.106C3.23858 8.7384 3.60004 8.50004 4 8.5H16V4.5H2Z"
                fill="#828282"/>
        </svg>

    );
};

export default IconTabs;