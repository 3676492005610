import MainLayout from "../widgets/mainLayout";
import DashboardTabs from "../widgets/dashboardTabs/dashboardTabs";
import {useGetAccountDashboardQuery} from "../store/API/userApi";
import {useLocation} from "react-router-dom";
import {
    useGetChartBalanceGainQuery,
    useGetChartBalanceQuery,
    useGetChartDayQuery,
    useGetChartDrawdownAndGainQuery,
    useGetChartDrawdownQuery,
    useGetChartHoursQuery,
    useGetChartMonthsQuery,
    useGetChartSymbolQuery, useGetChartTradersQuery
} from "../store/API/chartApi";
import React, {useEffect, useState} from "react";
import {Skeleton} from "@mui/material";

const AccountDashboard = () => {
    const location = useLocation()
    const id = location?.pathname?.split('/').pop()

    const [balanceChartUrl, setBalanceChartUrl] = useState('all')
    const [balanceGainChartUrl, setBalanceGainChartUrl] = useState('all')
    const [tradersChartUrl, setTradersChartUrl] = useState('current-month')
    const [dayChartUrl, setDayChartUrl] = useState('all')
    const [hoursChartUrl, setHoursChartUrl] = useState('all')
    const [drawdownChartUrl, setDrawdownChartUrl] = useState('all')
    const [drawdownAndGainChartUrl, setDrawdownAndGainChartUrl] = useState('current-month')

    const {
        data: dataAccount,
        isLoading: isLoadingAccount,
        error: errorAccount,
        refetch: refetch1
    } = useGetAccountDashboardQuery(id, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })

    const {data, isLoading, error, refetch: refetch2} = useGetChartBalanceQuery({
        id: `account/${id}`,
        url: balanceChartUrl
    }, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })
    const {
        data: dataTradersChart,
        isLoading: isLoadingTradersChart,
        error: error1,
        refetch: refetch3
    } = useGetChartTradersQuery({
        id: `account/${id}`,
        url: tradersChartUrl
    }, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })
    const {
        data: dataBalanceGain,
        isLoading: isLoadingBalanceGain,
        error: error2,
        refetch: refetch4
    } = useGetChartBalanceGainQuery({
        id: `account/${id}`,
        url: balanceGainChartUrl
    }, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })

    const {
        data: dataChartMonths,
        isLoading: isLoadingChartMonths,
        error: error6,
        refetch: refetch8
    } = useGetChartMonthsQuery({
        id: `account/${id}`,
        url: 'all'
    }, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })


    const {
        data: dataChartDrawdown,
        isLoading: isLoadingChartDrawdown,
        error: error7,
        refetch: refetch9
    } = useGetChartDrawdownQuery({
        id: `account/${id}`,
        url: drawdownChartUrl
    }, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })
    const {
        data: dataChartDrawdownAndGain,
        isLoading: isLoadingChartDrawdownAndGain,
        error: error8,
        refetch: refetch10
    } = useGetChartDrawdownAndGainQuery({id: `account/${id}`, url: drawdownAndGainChartUrl}, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })

    const updateDeposit = () => refetch1()
    console.log(dataAccount?.data?.product?.product_data)
    return (
        <MainLayout isSelect isLink={true} heading="Дашборд" accountNumber={dataAccount?.data?.login}
                    typeAccount={(dataAccount?.data?.product?.product_data?.slug === 'robot' && 'Робот Aggressive') || (dataAccount?.data?.product?.product_data?.slug === 'robot2' && 'Робот Conservative') || dataAccount?.data?.product?.product_data?.title}>
            {
                (
                    !isLoadingAccount &&
                    !isLoading &&
                    !isLoadingTradersChart &&
                    !isLoadingBalanceGain &&
                    !isLoadingChartMonths &&
                    !isLoadingChartDrawdown &&
                    !isLoadingChartDrawdownAndGain
                )
                    ?
                    <DashboardTabs
                        isTraderDasboard={false}

                        updateDeposit={updateDeposit}
                        isLoadingAccount={isLoadingAccount}
                        dataDashboard={dataAccount?.data}
                        balanceChartData={data}
                        dataTradersChart={dataTradersChart}
                        dataChartDrawdown={dataChartDrawdown}
                        dataBalanceGain={dataBalanceGain}
                        dataChartDrawdownAndGain={dataChartDrawdownAndGain}
                        dataChartMonths={dataChartMonths}

                        balanceChartUrl={setBalanceChartUrl}
                        tradersChartUrl={setTradersChartUrl}
                        balanceGainChartUrl={setBalanceGainChartUrl}
                        dayChartUrl={setDayChartUrl}
                        hoursChartUrl={setHoursChartUrl}
                        drawdownChartUrl={setDrawdownChartUrl}
                        drawdownAndGainChartUrl={setDrawdownAndGainChartUrl}

                    />
                    : <Skeleton variant="rounded" width={`100%`} height={433}/>
            }

        </MainLayout>
    );
};

export default AccountDashboard;
