import MainLayout from "../widgets/mainLayout";
import DashboardTabs from "../widgets/dashboardTabs/dashboardTabs";
import {useLocation} from "react-router-dom";
import {useGetTraderQuery} from "../store/API/tradersUserApi";
import {
    useGetChartBalanceGainQuery, useGetChartBalanceQuery, useGetChartDayQuery,
    useGetChartDrawdownAndGainQuery, useGetChartDrawdownQuery,
    useGetChartHoursQuery, useGetChartMonthsQuery,
    useGetChartSymbolQuery
} from "../store/API/chartApi";
import React, {useState} from "react";
import {Skeleton} from "@mui/material";


const TraderDashboard = () => {
    const location = useLocation()
    const id = location?.pathname?.split('/').pop()

    const {data: dataTrader, isLoading: isLoadingTrader,} = useGetTraderQuery(id, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })
    const [page, setPage] = useState(1);
    const {data, isLoading, error} = useGetChartBalanceQuery({id: `trader/${id}`, url: 'all'}, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })
    const {data: dataBalanceGain, isLoading: isLoadingBalanceGain} = useGetChartBalanceGainQuery({
        id: `trader/${id}`,
        url: 'all'
    }, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })
    const {data: dataSymbol, isLoading: isLoadingSymbol} = useGetChartSymbolQuery({id: `trader/${id}`, url: 'all'}, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })
    const {data: dataChartDay, isLoading: isLoadingChartDay} = useGetChartDayQuery({id: `trader/${id}`, url: 'all'}, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })
    const {data: dataChartHours, isLoading: isLoadingChartHours} = useGetChartHoursQuery({
        id: `trader/${id}`,
        url: 'all'
    }, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })
    const {data: dataChartMonths, isLoading: isLoadingChartMonths} = useGetChartMonthsQuery({
        id: `trader/${id}`,
        url: 'all'
    }, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })
    const {data: dataChartDrawdown, isLoading: isLoadingChartDrawdown} = useGetChartDrawdownQuery({
        id: `trader/${id}`,
        url: 'all'
    }, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })
    const {
        data: dataChartDrawdownAndGain,
        isLoading: isLoadingChartDrawdownAndGain
    } = useGetChartDrawdownAndGainQuery({id: `trader/${id}`, url: 'all'}, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };
    return (
        <MainLayout isSelect heading={`${dataTrader?.data?.name}`}>
            {
                (
                    !isLoadingTrader &&
                    !isLoading &&
                    !isLoadingBalanceGain &&
                    !isLoadingSymbol &&
                    !isLoadingChartDay &&
                    !isLoadingChartHours &&
                    !isLoadingChartMonths &&
                    !isLoadingChartDrawdown &&
                    !isLoadingChartDrawdownAndGain
                ) ?
                    <DashboardTabs
                        traderDashboard
                        dataTrader={dataTrader?.data}
                        balanceChartData={data}
                        dataSymbol={dataSymbol}
                        isLoadingSymbol={isLoadingSymbol}
                        dataChartDay={dataChartDay}
                        dataChartHours={dataChartHours}
                        dataChartDrawdown={dataChartDrawdown}
                        dataBalanceGain={dataBalanceGain}
                        dataChartDrawdownAndGain={dataChartDrawdownAndGain}
                        dataChartMonths={dataChartMonths}
                        isTrader
                        changePage={handleChangePage} page={page}
                    />
                    : <Skeleton variant="rounded" width={`100%`} height={433}/>
            }

        </MainLayout>
    );
};

export default TraderDashboard;
