import React, {FC, useState} from 'react';
import Paper from "@mui/material/Paper";
import {Chip, Stack, useMediaQuery} from "@mui/material";
import imgCopy from "../../shared/assets/images/tariff-copy.svg";
import imgRobot from "../../shared/assets/images/tariff-robot.svg";
import AddProductModal from "./modal/addProductModal";

interface IType {
    children?: any
}

const state = [
    // {
    //     id: 1,
    //     name: 'CopyTen',
    //     priceTitle: '',
    //     image: imgCopy,
    //     price: 'от 1.000₽',
    //     slug: 'copier20k',
    //     text: 'CopyTen - инновационный инструмент для сбора портфеля из нескольких трейдеров и выделения на каждого части депозита. Гибкие настройки инструмента позволяют увеличить прибыль и снизить риски. CopyTen предлагает несколько тарифов, подходящих для инвестиционных потребностей.',
    //     sale: {
    //         isSale: false,
    //         priceSale: '',
    //         text: ''
    //     }
    // },
    // {
    //     id: 2,
    //     name: 'Копировальщик',
    //     priceTitle: 'До 20.000$',
    //     image: imgCopy,
    //     price: '$2000',
    //     slug:'copier10k',
    //     text: 'Velit nunc ultrices sit est et varius. Tellus accumsan pretium sollicitudin elit purus morbi.Euismod fames ullamcorper eget eget mi nisi aliquet tortor. Etiam aenean mauris integer maecenas et in. Volutpat dolor id vulputate non sed arcu. Justo ut nisl tincidun',
    //     sale: {
    //         isSale: false,
    //         priceSale: '',
    //         text: ''
    //     }
    // },
    {
        id: 3,
        name: 'Робот Aggressive',
        priceTitle: '',
        image: imgRobot,
        price: '1.700₽',
        slug: null,
        text: 'Робот работает по двум стратегиям: откат в тренде и усреднение при развороте цены. Он автоматически открывает ордера при изменении цены в направлении тренда и закрывает их с прибылью при развороте. Это максимизирует возможность прибыли.',
        sale: {
            isSale: false,
            priceSale: '$10 000',
            text: 'Скидка'
        }
    },
    // {
    //     id: 4,
    //     name: 'Робот Conservative',
    //     priceTitle: '',
    //     image: imgRobot,
    //     price: '1.700₽',
    //     slug: null,
    //     text: 'Робот торгует по новой авторской стратегии с большим количеством ордеров с относительно маленькими объемами.',
    //     sale: {
    //         isSale: false,
    //         priceSale: '$10 000',
    //         text: 'Скидка'
    //     }
    // },
]

const ProductItem: FC<IType> = ({children}) => {
    const mediaQuery = useMediaQuery('(min-width:980px)');
    const [stateModal, setStateModal] = useState(state[0])
    const [openModal, setOpenModal] = useState(false);

    return (
        <Stack direction={mediaQuery ? "row" : "column"} spacing={7}>
            {
                state.map(item =>
                    <Paper
                        onClick={() => {
                            setStateModal(item)
                            setOpenModal(true)
                        }}
                        key={item.id}
                        sx={{
                            maxWidth: 300,
                            padding: '14px !important',
                            background:
                                (item.id === 3) ?
                                    `linear-gradient(180deg, #33223A 0%, rgba(51, 34, 58, 0) 93.75%)`
                                    : `linear-gradient(180deg, #242D28 0%, rgba(36, 45, 40, 0) 93.75%)`,
                            cursor: 'pointer',
                            "@media (max-width:980px)": {
                                maxWidth: '100%',
                                width: '100%',
                                minHeight: '345px'
                            }
                        }}>
                        <Stack spacing={2}>
                            <span
                                className={(item.id === 3) ? "h1 pink" : "h1 green"}>{item.name}</span>
                            <span className="h2">{item.priceTitle}</span>
                            <p className="subHeaders white-90">{item.text}</p>
                            <Stack alignItems="center" sx={{
                                "@media (max-width:980px)": {
                                    maxWidth: '100%',
                                    width: '100%',
                                    minHeight: '127px',
                                    marginTop: '49px !important'
                                }
                            }}>
                                <img src={item.image} alt="Копировальщик" style={{
                                    height: '100%',
                                    width: 'auto'
                                }}/>
                            </Stack>
                            <Chip label={item.sale.isSale ? item.price + ' ' + item.sale.priceSale : item.price}
                                  color="warning" variant="outlined" sx={{
                                position: 'absolute',
                                bottom: 14,
                                left: 14,
                            }}/>
                            {
                                item.sale.isSale &&
                                <Chip label={item.sale.text} color="error" variant="outlined" sx={{
                                    position: 'absolute',
                                    bottom: 14,
                                    right: 14,
                                }}/>
                            }
                        </Stack>
                    </Paper>
                )
            }
            {
                openModal && <AddProductModal stateModal={stateModal} openModal={openModal} closeModal={setOpenModal}/>
            }

        </Stack>
    );
};

export default ProductItem;
