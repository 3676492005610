import React, {useEffect, useState} from 'react';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {routes} from "./routes/routes";
import '../shared/assets/styles/reset.scss';
import '../shared/assets/styles/libs.scss';
import '../shared/assets/styles/globals.scss';
import Auth from "../pages/auth";
import {useAppSelector} from "../hooks/useRedux";
import Sidebar from "../widgets/sidebar";
import {Grid} from "@mui/material";


function App() {
    const {isAuth} = useAppSelector(state => state.authReducer)
    const [userToken, _] = useState<any>(localStorage.getItem('token'))
    const {pathname} = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }, [pathname])

    if (isAuth) navigate(0)

    return (
        <div style={{
            display: 'flex',
            maxWidth: '1280px',
            width: '100%',
            margin: '0 auto',
        }} className={'App'}>

            {userToken && <Grid sx={{
                width: 300,
                minWidth: 300,
                maxWidth: 300,

                "@media (max-width:1220px)": {
                    width: 'calc(100% - 10px)',
                    minWidth: '100px',
                    maxWidth: 'calc(100% - 10px)',
                }
            }}>
                <Sidebar isAdmin={pathname.includes('admin') && !isAuth}/>
            </Grid>}
            <div style={{
                width: '100%',
                marginTop: '20px',
            }}>
                <Routes>
                    {
                        userToken ?
                            routes.map(route =>
                                <Route
                                    path={route.path}
                                    element={route.element}
                                    key={route.path}
                                />
                            )
                            :
                            <Route path="*" element=<Auth/>/>
                    }
                </Routes>
            </div>
        </div>
    );
}

export default App;
